exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-example-json-at-buildtime-js": () => import("./../../../src/pages/example-JSON-at-buildtime.js" /* webpackChunkName: "component---src-pages-example-json-at-buildtime-js" */),
  "component---src-pages-example-page-js": () => import("./../../../src/pages/example-page.js" /* webpackChunkName: "component---src-pages-example-page-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-work-greenpatch-js": () => import("./../../../src/pages/work/greenpatch.js" /* webpackChunkName: "component---src-pages-work-greenpatch-js" */),
  "component---src-pages-work-jam-js": () => import("./../../../src/pages/work/jam.js" /* webpackChunkName: "component---src-pages-work-jam-js" */),
  "component---src-pages-work-js": () => import("./../../../src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */),
  "component---src-pages-work-lef-js": () => import("./../../../src/pages/work/lef.js" /* webpackChunkName: "component---src-pages-work-lef-js" */)
}

